<template>
  <div >
    <div>
      <b-row class="admin-text" >
        <h5 class="mb-3">Admin Users!</h5>
      </b-row>         
    </div>
  
    <div>
      <b-tabs content-class="mt-3">
        <!-- First Tab to Display Records -->
        <b-tab title="Admin Users" active>
          <b-row>
            <b-col sm="12" class="mb-3"><b-form-input v-model="SearchBy" placeholder="Search by name"></b-form-input></b-col>
            <!-- <b-col sm="4"><b-button>Submit Your Photos</b-button></b-col> -->
          </b-row>
            <b-alert show variant="success"  v-if="MsgDelSuccess">Record deleted successfully!</b-alert>
        <table class="table table-bordered table-striped">
          <thead>
            <tr class="text-center bg-success text-light">
              <th>Seq No</th>
              <th>User Name</th>
              <th>User Password</th>
              <th>User Status</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="Data in SearchList" v-bind:key="Data.seq_no">
              <td>{{Data.seq_no}}</td>
              <td class="text-left">{{Data.user_name}}</td>
              <td class="text-left">{{Data.user_pswd}}</td>
              <td class="text-left">{{Data.cde_status}}</td>
              <td>
                <a href="#" class="text-success"><i class="fas fa-edit"></i></a>
              </td>
              <td>
                <a class="text-danger"><i class="fas fa-trash-alt" v-on:click="DelAdminUser(Data.seq_no)"></i
                ></a>
              </td>
            </tr>
          </tbody>
        </table>          
        </b-tab>
        <!-- Second Tab to Create new Records -->        
        <b-tab title="Create New User">
          <b-row class="text-center">
            <b-col cols="8 my-4">
            <b-alert show variant="success"  v-if="MsgSuccess">New Admin user created successfully!</b-alert>
            <b-alert show variant="danger"  v-if="MsgDanger">Error! Some Issue while create a new record!</b-alert>                 
              <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <b-form-group id="input-group-2" label-for="username">
                  <b-form-input
                    id="username"
                    v-model="form.username"
                    placeholder="Enter user name"
                    required
                  ></b-form-input>
                </b-form-group>   
                <b-form-group id="input-group-2" label-for="password">
                  <b-form-input
                    id="password"
                    v-model="form.password"
                    placeholder="Enter password"
                    required
                  ></b-form-input>
                </b-form-group>   
                <b-button pill type="submit" variant="primary">Submit</b-button>
                <b-button pill type="reset" variant="danger">Reset</b-button>
              </b-form>        
            </b-col>
          </b-row>          

        </b-tab>
        <!-- Third Tab to see Inactive Records -->        
        <b-tab title="Inactive Records">
          <p>Inactive Records</p>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

Vue.use(Vueaxios,axios)
  export default {
    data() {
      return {
        form: {
          username: '',
          password: '',
        },
        RespArray: [],
        RespAllAdminUsers: [],
        RespArrayIns: [],
        show: true,
        MsgSuccess: false,
        MsgDanger: false,
        MsgDelSuccess: false,
        MsgDelSDancger: false,
        SearchBy: '',
      }
    },
    mounted()
    {
      this.GetAllAdminUsers()
    },    
    methods: {
      onSubmit(event) {
      event.preventDefault()
      this.InsAdminUser()
        // alert(JSON.stringify(this.form))

      },
      GetAllAdminUsers(){  
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=GetAllAdminUsers')
        .then((resp)=>{
        this.RespAllAdminUsers=resp.data.AllAdminUsers;
      })
      },      
      InsAdminUser(){  
        const REQformData = new FormData();
        REQformData.append('user_name', this.form.username);
        REQformData.append('user_pswd', this.form.password);          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=InsAdminUser',REQformData)
        .then((resp)=>{
          this.RespArrayIns=resp.data.message;
          this.form.username = ''
          this.form.password = ''
          alert('Inserted Successfully!!')
          this.GetAllAdminUsers()
      })
      },
      DelAdminUser(seq_no){
       const InsformData = new FormData();
        InsformData.append('seq_no', seq_no);
        axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=DelAdminUser', InsformData)
        .then((resp)=>{
        this.RespArray=resp.data.message;
        alert('Deleted Successfully!!')
        this.GetAllAdminUsers();
       }).catch(error => console.log(error));
       },         
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.username = ''
        this.form.password = ''
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
        this.show = true
        this.MsgSuccess= false
        })
      }
    },
    computed: {
    SearchList: function(){
        return this.RespAllAdminUsers.filter((RespAllAdminUsers) => {
        return RespAllAdminUsers.user_name.toLowerCase().match(this.SearchBy.toLowerCase())
      });
    },   
  }    
  }
</script>

<style scoped>
.admin-main .admin-form-login {
  padding: 40px 200px 80px 50px;    
}
</style>
